import React from 'react';
import ContentEl from '../components/element.js';
import split from 'lodash/split';
import pull from 'lodash/pull';
import includes from 'lodash/includes';

export const collectIdsAndDocs = doc => {
  let _u = doc ? (doc.data() ? doc.data() : {}) : {};
  _u.id = doc ? doc.id : '';
  return { ..._u };
};

export const renderContent = content => (
  <>{content && content.map(el => <ContentEl key={el.id} el={el} />)}</>
);

export const setDocHeight = el => {
  let vh = window.innerHeight * 0.01;

  if (!el || !el.current) {
    return;
  }
  el.current.style.setProperty('--vh', `${vh}px`);
};

export function getColorMode(pathname) {
  const segments = pull(split(pathname, '/'), ''); // split url and cleanup empty strings
  let mode;
  if (includes(pathname, 'insights') || includes(pathname, 'about')) {
    mode = 'light';
  } else if (segments.length >= 2 && includes(pathname, 'work')) {
    mode = 'light';
  } else {
    mode = 'dark';
  }

  return mode;
}

export function setColorMode(pathname) {
  const colorMode = getColorMode(pathname);
  if (colorMode === 'dark') {
    document.documentElement.style.setProperty('--color-background', '#000000');
    document.documentElement.style.setProperty('--color-text', '#ffffff');
    document.documentElement.style.setProperty('--color-navigation', '#ffffff');
  } else if (colorMode === 'light') {
    document.documentElement.style.setProperty('--color-background', '#F7F7F5');
    document.documentElement.style.setProperty('--color-text', '#000000');
    document.documentElement.style.setProperty('--color-navigation', '#000000');
  } else if (colorMode === 'caseStudy') {
    document.documentElement.style.setProperty('--color-background', '#F7F7F5');
    document.documentElement.style.setProperty('--color-text', '#000000');
    document.documentElement.style.setProperty('--color-navigation', '#ffffff');
  }
}
