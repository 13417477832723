import React from 'react';
import rehypeReact from 'rehype-react';
import { css } from '@emotion/core';

import { InView } from 'react-intersection-observer';
import gsap from 'gsap';

import { HEADING_LARGE, SPAN } from '../elements/typography';

import NavLink from './nav-link';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: { p: HEADING_LARGE },
}).Compiler;

const TextBanner = ({ textNode, text, linkText, linkUrl }) => {
  const content = textNode.childMarkdownRemark.htmlAst;

  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target.firstElementChild;

      tl.to(el, {
        duration: 1.6,
        ease: 'power3.out',
        y: 0,
        opacity: 1,
      });
    }
  };

  return (
    <div
      css={css`
        width: 100%;
        height: 70vh;
        margin: 0 auto;
        padding: 45px 0px 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        text-align: center;
        @media (min-width: 768px) {
          height: 100vh;
        }
      `}
    >
      <InView
        threshold={0.25}
        triggerOnce={true}
        onChange={(inView, entry) => handleReveal(inView, entry)}
      >
        <div
          css={css`
            opacity: 0;
            transform: translatey(10%);
            @media (min-width: 768px) {
              max-width: 90vw;
              padding: 0px 15px;
            }
          `}
        >
          {renderAst(content)}
          <NavLink
            menuLink={true}
            css={css`
              margin-top: 16px;
              display: inline-block;
              /* border-bottom: 1px solid white; */
              &:hover {
                /* border-bottom: 1px solid white; */
              }
              @media (min-width: 768px) {
                margin-top: 32px;
              }
            `}
            url={linkUrl}
          >
            <SPAN>{linkText}</SPAN>
          </NavLink>
        </div>
      </InView>
    </div>
  );
};

export default TextBanner;
