import React from 'react';
import { css } from '@emotion/core';
import { InView } from 'react-intersection-observer';
import gsap from 'gsap';
import rehypeReact from 'rehype-react';
import { P, H2, H4, LINK, LIST, LISTITEM } from '../elements/typography';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: { p: P, h2: H2, h3: H4, a: LINK, li: LISTITEM, ul: LIST },
}).Compiler;

const TextContent = ({ el }) => {
  const textAlign = el.textAlign || 'left';

  const alignmentY = el.alignmentY || 'middle';
  const width = el.width || 'half';
  const height = el.height || 'normal';
  const content = el.textNode.childMarkdownRemark.htmlAst;

  let alignmentX;
  switch (el.alignment) {
    case 'left':
      alignmentX = '0';
      break;
    case 'center':
      alignmentX = '0 auto';
      break;
    case 'right':
      alignmentX = '0 0 0 50%';
      break;
    default:
      alignmentX = '0';
  }

  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target;

      tl.to(el, {
        duration: 1.6,
        ease: 'power3.out',
        y: 0,
        opacity: 1,
      });
    }
  };

  return (
    <InView
      threshold={0.25}
      triggerOnce={true}
      onChange={(inView, entry) => handleReveal(inView, entry)}
      className="textContent"
      css={css`
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: ${alignmentY === 'top' ? 'flex-start' : 'center'};
        align-items: flex-start;
        min-height: 40vh;
        padding: 64px 0;
        @media (min-width: 768px) {
          min-height: ${height === 'half' ? '50vh' : 'auto'};
          padding: ${alignmentY === 'top'
            ? '2vw 0 16vw 0'
            : 'calc(8vw - 16px) 0 8vw 0'};
        }
      `}
    >
      <div
        css={css`
          max-width: 100%;
          text-align: ${textAlign};
          @media (min-width: 768px) {
            width: 100%;
            max-width: ${width === 'half' ? '50%' : '60%'};
            margin: ${alignmentX};
          }
          h4 {
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 1em;
            &:last-child {
              margin-bottom: 0;
            }

            & + h4 {
              margin-top: 2.5em;
            }
          }
          ul {
            & + h6 {
              margin-top: 2.5em;
            }
          }
          a {
            border-bottom: 1px solid;
            &:hover {
              border-color: transparent;
            }
          }
          > div {
            p:last-child {
              margin-bottom: 0;
            }
          }
        `}
      >
        {renderAst(content)}
      </div>
    </InView>
  );
};

export default TextContent;
