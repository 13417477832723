import React from 'react';
import { getComponent } from '../utilities/datocms';

const ContentEl = ({ el, id }) => {
  const { El, elProps } = getComponent(el);

  return El ? <El {...elProps} key={id} /> : null;
};

export default ContentEl;
