
import React from 'react';
import { css } from '@emotion/core';
import { H6 } from '../elements/typography.js';

const Heading = ({ heading }) => (
  <div
    css={css`
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 5px;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 15px 0px 30px;
    `}>
    <div
      css={css`
        @media (min-width: 768px) {
          width: 50%;
          padding: 0px 6px;
        }
      `}>
      <H6>{heading}</H6>
    </div>
  </div>
);

export default Heading;