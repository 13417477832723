import React, { useEffect, useState } from 'react';
import includes from 'lodash/includes';
import { css } from '@emotion/core';
import NavLink from './nav-link';
import { H4, LINK } from '../elements/typography.js';

const Footer = ({ pathname }) => {
  const [footerColor, setFooterColor] = useState(null);
  useEffect(() => {
    if (includes(pathname, 'about')) {
      setFooterColor('light');
    } else {
      setFooterColor('dark');
    }
  }, [pathname]);

  const year = new Date().getFullYear();

  return (
    <footer
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        z-index: 0;
        padding: 16px;
        background: ${footerColor === 'dark' ? '#000000' : '#F7F7F5'};

        @media (min-width: 768px) {
          flex-direction: row;
          padding: 32px;
          justify-content: space-between;
        }
      `}
    >
      <H4
        css={css`
          color: ${footerColor === 'dark' ? 'white' : 'inherit'};
        `}
      >
        ©{year}, HIGHSNOBIETY
      </H4>
      <nav
        css={css`
          a {
            margin-left: 8px;
            color: ${footerColor === 'dark' ? 'white' : 'inherit'};
            text-transform: uppercase;
            &:hover {
              border-bottom: 1px solid;
            }
          }
        `}
      >
        <NavLink url="/jobs/">Jobs</NavLink>
        <LINK href="https://www.highsnobiety.com/imprint/">Imprint</LINK>
      </nav>
    </footer>
  );
};

export default Footer;
