import React from 'react';
import { css } from '@emotion/core';
import Image from 'gatsby-image';
import { InView } from 'react-intersection-observer';
import gsap from 'gsap';

const ImageSingle = ({ image, alignment, id, grid, delay }) => {
  const handleReveal = (inView, entry) => {
    if (inView) {
      const tl = new gsap.timeline();
      const el = entry.target.querySelector('.gatsby-image-wrapper');
      tl.to(el, { duration: 1.6, ease: 'power3.out', y: 0, opacity: 1 });
    }
  };
  if (grid) {
    return (
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 100%;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
          @media (min-width: 768px) {
            width: 50%;
            margin-bottom: 0px;
          }
        `}
      >
        {image && (
          <div
            css={css`
              padding: 0 8px;
              flex-basis: 100%;
              flex-grow: 1;
              max-width: 100%;
              div {
                overflow: hidden;
              }
              .gatsby-image-wrapper {
                transform: translateY(10%);
                opacity: 0;
              }
            `}
          >
            <InView
              threshold={0.05}
              triggerOnce={true}
              onChange={(inView, entry) => handleReveal(inView, entry)}
            >
              <Image fluid={image.fluid} alt={image.alt} />
            </InView>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          margin: 0px -8px 16px -8px;

          @media (min-width: 768px) {
            justify-content: ${alignment === 'right'
              ? 'flex-end'
              : 'flex-start'};
            margin: 0px -8px 0px -8px;
          }
        `}
      >
        {image && (
          <div
            css={css`
              padding: 0 8px;
              flex-basis: 100%;
              flex-grow: 1;
              max-width: 100%;

              div {
                overflow: hidden;
              }
              @media (min-width: 768px) {
                margin-bottom: 16px;
                flex-basis: ${alignment === 'fullwidth' ? '100%' : '50%'};
                max-width: ${alignment === 'fullwidth' ? '100%' : '50%'};
              }
              .gatsby-image-wrapper {
                transform: translateY(10%);
                opacity: 0;
              }
            `}
          >
            <InView
              threshold={0.05}
              triggerOnce={true}
              // as="div"
              onChange={(inView, entry) => handleReveal(inView, entry)}
            >
              <Image fluid={image.fluid} alt={image.alt} />
            </InView>
          </div>
        )}
      </div>
    );
  }
};

export default ImageSingle;
