import React from 'react';
import { css } from '@emotion/core';
import ImageSingle from './image-single';

const ImageGrid = ({ images, id }) => (
  <div
    css={css`
      margin: 0px -8px 16px -8px;
      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -8px 16px -8px;
      }
    `}
  >
    {images &&
      images.map((image, index) =>
        image ? (
          <ImageSingle
            key={index}
            image={image}
            alignment={image.alignment}
            grid={true}
            delay={index}
          />
        ) : null,
      )}
  </div>
);

export default ImageGrid;
