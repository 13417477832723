import React from 'react';
import { css } from '@emotion/core';
import { P, LINK } from '../elements/typography';

const SocialLinks = ({ el }) => {
  return (
    <div
      css={css`
        max-width: 100%;
        @media (min-width: 768px) {
          max-width: 50%;
          margin-bottom: 64px;
        }
      `}
    >
      <P
        css={css`
          margin-bottom: 32px;
        `}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, labore?
      </P>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          a {
            margin-bottom: 8px;
          }
        `}
      >
        <LINK href={el.instagram}>Instagram</LINK>
        <LINK href={el.linkedin}>LinkedIn</LINK>
        <LINK href={el.twitter}>Twitter</LINK>
      </div>
    </div>
  );
};

export default SocialLinks;
