import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import Player from '@vimeo/player';

const VideoBlock = ({ video }) => {
  const [isMuted, setIsMuted] = useState(true);
  const videoContainer = useRef(null);
  let player = useRef(null);

  useEffect(() => {
    if (!player.current) {
      player.current = new Player(videoContainer.current, {
        id: video.providerUid,
        title: false,
        controls: false,
        byline: false,
        autoplay: true,
        muted: true,
        loop: true,
        autopause: false,
      });
    }

    player.current.ready().then(function() {
      // The player is ready
      player.current.setVolume(0);
    });
  }, [video.providerUid]);

  const toggleAudio = () => {
    if (isMuted) {
      player.current.setMuted(false).then(muted => {
        player.current.setCurrentTime(0);
        player.current.setVolume(0.25);
        setIsMuted(false);
      });
    } else {
      player.current.setMuted(true).then(muted => {
        setIsMuted(true);
        player.current.setVolume(0);
      });
    }
  };

  return (
    <div
      className="video-block"
      css={css`
        position: relative;
        width: 100%;
        margin-bottom: 16px;
      `}
    >
      <div
        ref={videoContainer}
        css={css`
          position: relative;
          padding-bottom: 56.25%; /* 16:9 */
          height: 0;
          background: #000000;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `}
      ></div>
      <button
        css={css`
          position: absolute;
          top: 16px;
          right: 16px;
          background: transparent;
          border: 1px solid #ffffff;
          font-size: 16px;
          text-transform: uppercase;
          color: #ffffff;
          padding: 0.25em 0.5em;
          min-width: 100px;
          cursor: pointer;
          &:hover {
            background: #ffffff;
            color: #000000;
          }
        `}
        onClick={toggleAudio}
      >
        {isMuted ? 'Unmute' : 'Mute'}
      </button>
      {isMuted}
    </div>
  );
};

export default VideoBlock;
