import React from 'react';
import { css } from '@emotion/core';
import Image from 'gatsby-image';

const ImageSingle = ({ fluid }) => (
  <div
    css={css`
      padding: 0 6px;
      flex-basis: 0;
      flex-grow: 1;
    `}
  >
    <Image fluid={fluid} />
  </div>
);

const ImageGroup = ({ images, id }) => (
  <div
    css={css`
      margin: 0px -6px;
      display: flex;

      @media (max-width: 767px) {
        flex-direction: column;
        margin: 15px -6px;
      }
    `}
  >
    {images &&
      images.map((image, index) => {
        if (image) {
          return (
            <ImageSingle key={`image--${id}--${index}`} fluid={image.fluid} />
          );
        } else {
          return null;
        }
      })}
  </div>
);

export default ImageGroup;
