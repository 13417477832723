import styled from '@emotion/styled';

export const H5 = styled('h5')`
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Univers Roman', sans-serif;
`;

export const HEADING_LARGE = styled('h2')`
  font-size: 36px;
  font-weight: 400;
  font-family: 'Univers Bold Condensed', sans-serif;
  font-stretch: condensed;
  text-transform: uppercase;
  line-height: 0.85em;
  letter-spacing: -0.1vw;
  margin: 0px;

  @media (min-width: 560px) {
    font-size: 32px;
  }
  @media (min-width: 768px) {
    font-size: 8vw;
  }
  @media (min-width: 1200px) {
    font-size: 80px;
    letter-spacing: -0.03em;
  }
  @media (min-width: 1480px) {
    font-size: 112px;
  }
`;

export const HeadlineSpan = styled('span')`
  font-size: 36px;
  font-weight: 400;
  font-family: 'Univers Bold Condensed', sans-serif;
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: -0.02em;
  display: inline;
  text-align: center;
  border-bottom: 0.1em solid var(--color-text);

  @media (min-width: 560px) {
    font-size: 32px;
  }
  @media (min-width: 768px) {
    font-size: 8vw;
  }
  @media (min-width: 1200px) {
    font-size: 80px;
    letter-spacing: -0.03em;
  }
  @media (min-width: 1480px) {
    font-size: 112px;
  }
`;

export const H1 = styled('h1')`
  font-size: 36px;
  font-weight: 400;
  font-family: 'Univers Bold Condensed', sans-serif;
  font-stretch: condensed;
  text-transform: uppercase;
  line-height: 0.85em;
  letter-spacing: -0.1vw;
  margin: 0px;
  @media (min-width: 767px) {
    font-size: 8vw;
  }
  @media (min-width: 1200px) {
    font-size: 96px;
  }
  @media (min-width: 1480px) {
    font-size: 128px;
  }
`;

export const H2 = styled('h2')`
  font-size: 28px;
  font-weight: 400;
  font-family: 'Univers Bold Condensed', sans-serif;
  text-transform: uppercase;
  line-height: 0.9;
  letter-spacing: -0.02em;
  @media (min-width: 560px) {
    font-size: 32px;
  }
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 1200px) {
    font-size: 56px;
    letter-spacing: -0.03em;
  }
  /* @media (min-width: 1480px) {
    font-size: 72px;
  } */
`;

export const H3 = styled('h3')`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  /* @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
  @media (min-width: 1440px) {
    font-size: 20px;
  } */
`;

export const H4 = styled('h4')`
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  /* @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
  @media (min-width: 1440px) {
    font-size: 20px;
  } */
`;

export const H6 = styled('h6')`
  font-family: 'Univers Roman';
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.1;
  font-weight: normal;
  margin: 0px;
  letter-spacing: 0.02em;

  /* @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
  @media (min-width: 1440px) {
    font-size: 20px;
  } */
`;

export const P = styled('p')`
  font-size: 16px;
  line-height: 1.5;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  /* @media (min-width: 1200px) {
    font-size: 20px;
  }

  @media (min-width: 1440px) {
    font-size: 20px;
    line-height: 1.4;
  } */
`;
export const SPAN = styled('span')`
  font-size: 16px;
  line-height: 1.5;
  /* @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1480px) {
    font-size: 20px;
    line-height: 1.4;
  } */
`;
// export const LINK = styled.a.attrs()({
//   target: 'blank',
// })`
//   font-size: 16px;
//   line-height: 1.5;
//   color: inherit;
//   text-transform: uppercase;
//   text-decoration: none;
// `;

export const LINK = styled('a')`
  font-size: 16px;
  line-height: 1.5;
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid;
  }
`;

LINK.defaultProps = { target: 'blank' };

export const BUTTON = styled('a')`
  background-color: black;
  color: white;
  padding: 0.75em 2em;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.05em;
  @media (min-width: 768px) {
    font-size: 18px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
  @media (min-width: 1480px) {
    font-size: 16px;
  }
`;
export const SMALL = styled('p')`
  font-family: 'Univers Roman';
  font-size: 26px;
  line-height: 1.5;
  @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1800px) {
    font-size: 28px;
  }
`;

export const LIST = styled('ul')`
  padding: 0;
  margin: 0 0 0 0;
`;
export const LISTITEM = styled('li')`
  font-size: 16px;
  line-height: 1.5;

  margin-bottom: 0.75vw;
  list-style: none;
  &:before {
    content: '—';
    margin-right: 8px;
    vertical-align: text-bottom;
  }
  /* @media (min-width: 768px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
  }
  @media (min-width: 1480px) {
    font-size: 20px;
  } */
`;

export const Logo = styled('span')`
  font-size: 20px;
  line-height: 1em;
  text-transform: uppercase;
  font-family: 'Univers Bold Condensed', sans-serif;

  @media (min-width: 768px) {
    font-size: 30px;
  }
`;
