import React from 'react';
import { css } from '@emotion/core';
import { H6, P } from '../elements/typography.js';

const TextBlock = ({ heading, paragraph }) => {
  return (
    <div
      css={css`
        margin: 0 auto;
        padding: 45px 0px 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (min-width: 1200px) {
          padding: 90px 0px;
        }

        /* temp workaround for html solution */
        h6 {
          font-family: 'Univers Roman';
          text-transform: uppercase;
          font-size: 16px;
          line-height: 1.5;
          font-weight: normal;
          margin: 0px;
          letter-spacing: 1px;
          margin: 30px 0px 15px;

          @media (min-width: 768px) {
            font-size: 18px;
            margin-top: 45px;
          }
          @media (min-width: 1200px) {
            font-size: 1.5vw;
          }
          @media (min-width: 1800px) {
            font-size: 28px;
          }
          &:first-of-type {
            margin-top: 0px;
          }
        }
      `}
    >
      <div
        css={css`
          @media (min-width: 768px) {
            width: 50%;
            padding: 0px 6px;
          }
        `}
      >
        {heading && <H6>{heading}</H6>}
        <P dangerouslySetInnerHTML={{ __html: paragraph }}></P>
      </div>
    </div>
  );
};

export default TextBlock;
